import React from 'react'
import PrimaryAppBar from "../Components/PrimarySearchAppBar";
import './InvoicePage.css'
//import InvoicePage from '../Components/InvoiceComponents/InvoicePage'
import InvoiceListSelection from '../Components/InvoiceListSelection'


export default function InvoicePage() {

  return ( 
    <div>
      <PrimaryAppBar />
      <InvoiceListSelection />
    </div>
  )
}
