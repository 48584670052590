//import useState hook to create menu collapse state
import React, { useState } from "react";

//import react pro sidebar components
import {
  ProSidebar,
  Menu,
  MenuItem,
  //  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
//import icons from react icons
import { FaList } from "react-icons/fa";
import { FiHome, FiLogOut } from "react-icons/fi";
import { BiCog } from "react-icons/bi";
import { AiOutlineCalculator } from "react-icons/ai";
//import {GrAddCircle}from "react-icons/gr";
import {MdOutlineManageAccounts}from "react-icons/md";
//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "./PrimarySidebar.css";
import { UserLogout } from "../ModelActions/UserHelper";
import { read_cookie } from "sfcookies";
//import InvoiceListSelection from "./InvoiceListSelection";
//import HomePage from "../Pages/Home";
//import Calculator from "../Pages/Calculators";
//import Inventory from "../Pages/Inventory";
//import QuoteRequest from "../Pages/QuoteRequest";
//import SRManagers from "../Pages/SRManagers";
//import Settings from "../Pages/Settings";


const PrimarySidebar = ({ menuCollapse,closeSidebar }) => {
  //create initial menuCollapse state using useState hook
 //  const [menuCollapse, setMenuCollapse] = useState(Boolean(props.Collapsed))
 
  //create a custom function that will change menucollapse state from false to true and true to false
  const [Mactive, setActive] = useState("0");
  function handleSidebarMenuClick() {
    // console.log(JSON.stringify(e.target.id));
    // setActive(e.target.id);
    

    closeSidebar();
  
  }
  const navigate=useNavigate();
  return (
    <>
      <div id="header">
        {/* collapsed props to change menu size using menucollapse state */}
        <ProSidebar collapsed={menuCollapse}>
          {/* <SidebarHeader>
          <div className="logoText">
             
              <p>{menuCollapse ? "Logo" : "Big Logo"}</p>
            </div>
            <div className="closeMenu" onClick={menuIconClick}>
              
 
            </div>
          </SidebarHeader> */}
          <SidebarContent>
          {read_cookie("UID").toString() === "1"?
            (<Menu iconShape="square">
           
              <MenuItem
                key="1"
                active={Mactive === "1"}
                onClick={()=>{setActive("1");handleSidebarMenuClick();navigate('/');}}
                icon={<FiHome />}>
                {menuCollapse === false && "Home"}
                
              </MenuItem>

              <MenuItem
                key="2"
                active={Mactive === "2"}
                onClick={()=>{setActive("2");handleSidebarMenuClick();navigate('/SRManagers');}}
                icon={<FaList  />}>
                {menuCollapse === false && "TSR "}
              </MenuItem>
              <MenuItem
                key="4"
                active={Mactive === "4"}
                onClick={()=>{handleSidebarMenuClick();navigate('/Calculator');setActive("4");}}
                icon={<AiOutlineCalculator/>}>
                {menuCollapse === false && "Calculator"}
              </MenuItem>

              <MenuItem
                key={"6"}
                active={Mactive === "8"}
                onClick={()=>{handleSidebarMenuClick();navigate('/Inventory');setActive("8");}}
                icon={<MdOutlineManageAccounts />}>
                {menuCollapse === false && "Inventory"}
              </MenuItem>

              <MenuItem
                key="7"
                active={Mactive === "7"}
                icon={<BiCog />}
                to='/Settings'
                onClick={()=>{handleSidebarMenuClick();navigate('/Settings');setActive("7");}}>
              {menuCollapse === false && "Settings"} 
              </MenuItem>

              <MenuItem
              key="4"
              active={Mactive === "4"}
              onClick={()=>{handleSidebarMenuClick();navigate('/CustomerList');setActive("4");}}
              icon={<AiOutlineCalculator/>}>
              {menuCollapse === false && "CustomerList"}
            </MenuItem>
            <MenuItem
              key="4"
              active={Mactive === "5"}
              onClick={()=>{handleSidebarMenuClick();navigate('/QuoteList');setActive("5");}}
              icon={<AiOutlineCalculator/>}>
              {menuCollapse === false && "Quote List"}
            </MenuItem>
            <MenuItem
              key="4"
              active={Mactive === "6"}
              onClick={()=>{handleSidebarMenuClick();navigate('/CreateInvoice');setActive("6");}}
              icon={<AiOutlineCalculator/>}>
              {menuCollapse === false && "HubSpot Invoice"}
            </MenuItem>
           
            </Menu>
            
            
            ):
            (<Menu iconShape="square">
           
            <MenuItem
              key="1"
              menu
              disabled={false}
              active={Mactive === "1"}
              onClick={()=>{setActive("1");handleSidebarMenuClick();navigate('/');}}
              icon={<FiHome />}>
              {menuCollapse === false && "Home"}
              
            </MenuItem>


            <MenuItem
                key="4"
                active={Mactive === "4"}
                onClick={()=>{handleSidebarMenuClick();navigate('/Calculator');setActive("4");}}
                icon={<AiOutlineCalculator/>}>
                {menuCollapse === false && "Calculator"}
              </MenuItem>


            <MenuItem
              key="4"
              active={Mactive === "4"}
              onClick={()=>{handleSidebarMenuClick();navigate('/CustomerList');setActive("4");}}
              icon={<AiOutlineCalculator/>}>
              {menuCollapse === false && "CustomerList"}
            </MenuItem>
          </Menu>)
            }
          </SidebarContent>
          <SidebarFooter className="sidebarfooter">
            <Menu iconShape="square">
              <MenuItem icon={<FiLogOut />} onClick={() => {navigate('/');UserLogout();}}>
                {menuCollapse === false && "Logout"}
              </MenuItem>
            </Menu>
          </SidebarFooter>
        </ProSidebar>
      </div>
    </>
  );
};

export default PrimarySidebar;
