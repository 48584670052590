import React from "react";
import PrimaryAppBar from "../Components/PrimarySearchAppBar";
import UpdateEntities from "../Components/UpdateEntities";
class Settings extends React.Component{
    render(){
        return(
            <div>
            <PrimaryAppBar/>
                <UpdateEntities />
            </div>
        );
    }
}
 export default Settings;