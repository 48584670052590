import React from 'react'
import { useLocation } from 'react-router-dom'
import InvoicePreviewPage from './InvoicePreviewPage';
export default function InvMiddile() {
    const search = useLocation().search;
    const dealid = new URLSearchParams(search).get('dealid');
    const quoteid = new URLSearchParams(search).get('quoteid');
  return (
    <div>
      <InvoicePreviewPage quotno={quoteid} dealID={dealid} />
    </div>
  )
}
