import React from "react";
import { read_cookie } from "sfcookies";
import PrimaryAppBar from "../Components/PrimarySearchAppBar";

const HomePage = () => {
  return (
    <>
            <PrimaryAppBar /> 
     <h1>Welcome {read_cookie("AUTH").toString()}</h1>
    </>
  );
};
export default HomePage;
