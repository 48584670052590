import React from "react";
import CustomerList from "../Components/CustomerList";
import PrimaryAppBar from "../Components/PrimarySearchAppBar";
import axios from "axios";
import { read_cookie } from "sfcookies";
class CustomerListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state={
        rows:[]
    }
    this.updateState=this.updateState.bind(this);
    this.GetCustomer();
  }
  updateState(rowss)
  {
    this.setState({
        rows:rowss
    });
    console.log(rowss)
  }
   GetCustomer(){
    const userinfo = {
      userid: read_cookie("UID"),
    };
    axios
      .post(global.config.Server.serverName + "/getCustomers", userinfo)
      .then((res) => {
        this.updateState(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  render() {
    return (
      <>
        <PrimaryAppBar />
        <h3>Customer List</h3>
        <CustomerList  rows={this.state.rows}/>
      </>
    );
  }
}
export default CustomerListPage;
