
import React from 'react'
import './customerdec.css'
export default function CustomerDetails({ contact, company, deal,extra }) {
// onClick={()=>alert(JSON.stringify(company))}
    return (
        <div>
            
            <div className='detailBoxMain'>
                <div className='boxDetails'>
                <table>
                <thead></thead>
                        <tbody>
                    <tr><td className='tdcustomH'colSpan={3}><strong>Sold To</strong></td></tr>
                        <tr><td className='tdcustom'colSpan={3}>{company?.name}</td></tr>
                        <tr><td className='tdcustom' colSpan={3}>{company?.address  }</td></tr>
                        <tr><td className='tdcustom' colSpan={3}>{  company?.city+', ' + company?.state + ' ' + (company?.zip===undefined?"":company?.zip) }</td></tr>
                        <tr><td className='tdcustom'>Attn</td><td>:</td><td>{company?.contact_name}</td></tr>
                        <tr><td className='tdcustom'>Tel</td><td>:</td><td>{company?.phone}</td></tr>
                        <tr><td className='tdcustom'>Fax</td><td>:</td><td>{company?.phone}</td></tr>
                        <tr><td className='tdcustom'>Email</td><td>:</td><td>{company?.email}</td></tr>
                        </tbody>
                    </table>

                </div>
                <div className='boxDetails' >

                    <table>
                    <thead></thead>
                        <tbody>
                    <tr><td className='tdcustomH'colSpan={3}><strong>Ship to</strong></td></tr>
                        <tr><td className='tdcustom'colSpan={3}>{deal?.ship_to_name}</td></tr>
                        <tr><td className='tdcustom' colSpan={3}>{deal?.ship_to_address}</td></tr>
                        <tr><td className='tdcustom' colSpan={3}>{deal?.ship_to_city__state__zip_code}</td></tr>
                        <tr><td className='tdcustom'>Attn</td><td>:</td><td>{contact?.name}</td></tr>
                        <tr><td className='tdcustom'>Tel</td><td>:</td><td>{deal?.ship_to_phone}</td></tr>
                        <tr><td className='tdcustom'>Fax</td><td>:</td><td>{deal?.ship_to_fax}</td></tr>
                        <tr><td className='tdcustom'>Email</td><td>:</td><td>{deal?.ship_to_email}</td></tr>
                        </tbody>
                    </table>
                </div>

                <div className='boxDetails'>
                    <table>
                        <thead></thead>
                        <tbody>
                        <tr><td className='tdcustom'>Invoice #</td><td>:</td><td>{extra?.invNo}</td></tr>
                        <tr><td className='tdcustom'>Date</td><td>:</td><td>{new Date(deal?.createdate).toLocaleDateString()}</td></tr>
                        <tr><td className='tdcustom'>SalePerson</td><td>:</td><td>{deal?.sales_person}</td></tr>
                        <tr><td className='tdcustom'>Client PO</td><td>:</td><td>{deal?.client_p_o_}</td></tr>
                        <tr><td className='tdcustom'>Resale #</td><td>:</td><td>{deal?.resale__}</td></tr>
                        <tr><td className='tdcustom'>Terms</td><td>:</td><td>{deal?.terms}</td></tr>
                        <tr><td className='tdcustom'>FOB</td><td>:</td><td>{deal?.fob}</td></tr>
                        <tr><td className='tdcustom'>Ship Via</td><td>:</td><td>{deal?.ship_via}</td></tr>
                        <tr><td className='tdcustom'>Account</td><td>:</td><td>{deal?.account__}</td></tr>
                        </tbody>
                    </table>
                    {/* {/* <p className='alignText'> DocumentNo {deal?.hs_quote_number}</p>
                    <p className='alignText'>Date      : {new Date(deal?.createdate).toLocaleDateString()}</p> 
                    <p className='alignText'>SalePerson: {deal?.sales_person}</p>
                    <p className='alignText'>Client PO : {deal?.client_p_o_}</p>
                    <p className='alignText'>Resale #  : {deal?.resale__}</p>
                    <p className='alignText'>Terms     : {deal?.terms}</p>
                    <p className='alignText'>FOB       : {deal?.fob}</p>
                    <p className='alignText'>Ship Via  : {deal?.ship_via}</p>
                    <p className='alignText'>Account   : {deal?.account__}</p> */}
                </div>
            </div>
            <label className='lowerText'>SideMark   :{ ' '+deal?.sidemark}</label>
        </div>
    )
}
