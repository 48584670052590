import React from 'react'

import InvHeader from './InvHeader'
import CustomerDetails from './CustomerDetails'
import SpanningTable from './SpanningTable'
import './InvoiceComp.css'
import Footercomp from './footercomp'


export default function InvoicePreview({ line, company, contact, discount, fees, tax,deals,extra, callRefresh }) {
  function Print() {
    window.print();
  }
  //alert(JSON.stringify(deal));
  function elevatedAction(action) {
    if (action === 0) {
      Print();
    }
    else if (action === 1) {

    }
    else if (action === 2)  {
      //refresh
      callRefresh();
    }
   
  }

  return (
    <div className='headerClass'  >
      <InvHeader elevatedAction={elevatedAction} />
      <CustomerDetails  contact={contact} company={company} deal={deals} extra={extra} />
       <SpanningTable rowss={line} discount={discount} tax={tax} fees={fees} /> 
      <div className="footer"> <Footercomp /> </div>

    </div>
  )
}
