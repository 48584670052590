import axios from "axios";
import { useNavigate } from "react-router-dom";


export async function GetJSON(_path, _jsonData) {
  var errorMsg = "";
  var _data = [];
  await axios
    .post(_path, _jsonData)
    .then((res) => {
    //  console.log(" GetJSON Function Responds" + JSON.stringify(res.data));
    //  console.log(res.data);
      _data = res.data;
    })
    .catch((error) => {
    //  console.log(" GetJSON Function error" + error);
      errorMsg = error.toString();
    });
  const values = {
    Error: errorMsg,
    Data: _data,
  };
 // console.log("Before Return :" + JSON.stringify(values));
  return  await values;
}

export async function NavigateToPath(_path)
{
const navigate=useNavigate();
await navigate(_path);
}
