import * as React from "react";
import Dialog  from "@mui/material/Modal";
import './CustomerPopup.css'
import CustomerComp from "../Components/CustomerComp";

export default function CustomerPopup({ open, handlePopupClose }) {
  const  handlePopupClosemiddile=()=> handlePopupClose();
   
  return (


        <Dialog  open={open} onClose={() => handlePopupClose()} >
          <div className="popup-OuterContainer">
          <div className="popup">
            <CustomerComp myCallback={handlePopupClosemiddile} /> 
          </div> 
        </div>
        </Dialog >
  );
}
