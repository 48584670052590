import React from "react";
import { Box, Stack, TextField, Button } from "@mui/material";
import AlertDialog from "./AlertDialogue";
import { read_cookie } from "sfcookies";
import axios from "axios";
class SRManagerComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      Username: "",
      Password: "",
      RetypePass: "",
      email: "",
      phone: "",
      openAlertDialog: false,
      alertMessage: "",
      dialogTitle: "Error",
    };
    this.updateStates = this.updateStates.bind(this);
  }

  setAlertDialogOff = () => {
    this.updateStates("openAlertDialog", false);
  };
  setAlertDialogOn = () => {
    this.updateStates("openAlertDialog", true);
  };
  updateStates(stateName, stateValue) {
    console.log("working" + stateName + " " + stateValue.toString());
    this.setState({
      [stateName]: stateValue,
    });
  }
  tryToInsert = (event) => {
    event.preventDefault();
    this.updateStates("dialogTitle", "Error");
    if (this.state.firstName === "") {
      this.updateStates("alertMessage", "Enter A valid FirstName ");
      this.setAlertDialogOn();
    } else if (this.state.Username === "") {
      this.updateStates("alertMessage", "Enter A valid Username ");
      this.setAlertDialogOn();
    } else if (this.state.Password === "") {
      this.updateStates("alertMessage", "Enter A valid Password ");
      this.setAlertDialogOn();
    }
     else if (this.state.RetypePass === "") {
      this.updateStates("alertMessage", "Re-type the Password");
      this.setAlertDialogOn();
    }else if (this.state.RetypePass !== this.state.Password) {
      this.updateStates("alertMessage", "Entered Passwords Are not match");
      this.setAlertDialogOn();
    }
    else if (this.state.email === "") {
      this.updateStates("alertMessage", "Enter A valid Email ");
      this.setAlertDialogOn();
    } else if (this.state.phone === "") {
      this.updateStates("alertMessage", "Enter A valid PhoneNo ");
      this.setAlertDialogOn();
    }  else {
      const customerInfo = {
      Name: this.state.firstName+ " "+ this.state.lastName,
      Username: this.state.Username ,
      Password: this.state.Password,
      email: this.state.email,
      phone: this.state.phone,
      UserID: read_cookie("UID"),
      };
      axios
        .post(global.config.Server.serverName + "/AddNewSRMManager", customerInfo)
        .then((res) => {
          this.updateStates("alertMessage", res.data.toString());
          this.updateStates("dialogTitle", "Information");
          this.setAlertDialogOn();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  render() {
    return (
      <Box className="form-wrapper" container verient="form">
        <h2>TSR Registration</h2>
        <Stack className="StackClass" spacing={2}>
          <div className="buttonContainer">
            <TextField
              className="outlined-basic"
              label="First Name"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              required
              onChange={(e) => {
                this.updateStates("firstName", e.target.value);
              }}
            />
            <div className="space" />
            <TextField
              className="outlined-basic"
              label="Last Name"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => {
                this.updateStates("lastName", e.target.value);
              }}
            />
          </div>

          <TextField
            className="outlined-basic"
            label="Username"
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            required
            onChange={(e) => {
              this.updateStates("Username", e.target.value);
            }}
          />
          <div className="buttonContainer">
            <TextField
              className="outlined-basic"
              label="Password"
              type="Password"
              variant="outlined"
              size="small"
              fullWidth
              required
              onChange={(e) => {
                this.updateStates("Password", e.target.value);
              }}
            />{" "}
            <div className="space" />
            <TextField
              className="outlined-basic"
              label="Retype Password"
              type="Password"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => {
                this.updateStates("RetypePass", e.target.value);
              }}
            />
          </div>
          <div className="buttonContainer">
            <TextField
              className="outlined-basic"
              label="Email"
              type="email"
              variant="outlined"
              size="small"
              fullWidth
              required
              onChange={(e) => {
                this.updateStates("email", e.target.value);
              }}
            />
            <div className="space" />
            <TextField
              className="outlined-basic"
              label="Phone"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              required
              onChange={(e) => {
                this.updateStates("phone", e.target.value);
              }}
            />
          </div>

          <div className="buttonContainer">
            <Button
              variant="contained"
              onClick={(event) => {
                this.tryToInsert(event);
                console.log(this.state);
              }}
            >
              <b>Save</b>
            </Button>
            <div className="space" />
            <Button variant="outlined" type="reset">
              <b>Clear</b>
            </Button>
          </div>
        </Stack>
        <AlertDialog
          buttonText={"Ok"}
          dialogContent={this.state.alertMessage}
          dialogTitle={this.state.dialogTitle}
          open={this.state.openAlertDialog}
          parentClose={this.setAlertDialogOff}
        />
      </Box>
    );
  }
}
export default SRManagerComp;
