import React from "react";
import { read_cookie } from "sfcookies";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../Pages/LoginPage";
import HomePage from "../Pages/Home";
import PageNotFound from "../Pages/P404";
import Calculator from "../Pages/Calculators";
//import Inventory from "../Pages/Inventory";

import SRManagersPage from "../Pages/SRManagers";
import Settings from "../Pages/Settings";
import InventoryPage from "../Pages/InventoryPage";
import CustomerListPage from "../Pages/CustomerListPage";
import HistoryPage from "../Pages/HistoryPage";
import InventoryListPage from "../Pages/InventoryListPage";

//import InvoiceListSelection from "../Components/InvoiceListSelection";
import Invoice from "../Pages/InvoicePage";

import InvoiceSharePreviewPage from "../Pages/InvoiceSharePreviewPage";
import InvMiddile from "../Pages/InvMiddile";
export default class SiteRouter extends React.Component {
  render() {
    return (

      <Router>
        <Routes>
       
          {read_cookie("AUTH")===null ||read_cookie("AUTH").toString() === "" ? (
            <>
            <Route exact path='/' element={<Login />}></Route>
            <Route exact path='/Shared' element={<InvoiceSharePreviewPage />} ></Route>
            <Route exact path='/InventoryList' element={<InventoryPage />}></Route>
            <Route exact path='/*' element={<PageNotFound />} ></Route>
            </>)
            :
            read_cookie("ID").toString() === "1" ?
              (<>

                <Route exact path='/' element={<HomePage />}></Route>
                <Route exact path='/Calculator' element={<Calculator />}></Route>
                <Route exact path='/SRManagers' element={<SRManagersPage />}></Route>
                <Route exact path='/Settings' element={<Settings />}></Route>
                <Route exact path='/CustomerList' element={<CustomerListPage />}></Route>
                <Route exact path='/QuoteList' element={<HistoryPage />}></Route>
                <Route exact path='/Inventory' element={<InventoryPage />}></Route>
                <Route exact path='/InventoryList' element={<InventoryListPage />}></Route>
                <Route exact path='/CreateInvoice' element={<Invoice />}></Route>
                <Route exact path='/Preview' element={<InvMiddile />}></Route>
                <Route exact path='/Shared' element={<InvoiceSharePreviewPage />} ></Route>
                
                <Route exact path='/*'  element={<PageNotFound />} ></Route>
              </>) :
              (<>
                <Route exact path='/' element={<HomePage />}></Route>
                <Route exact path='/Calculator' element={<Calculator />}></Route>
                <Route exact path='/CustomerList' element={<CustomerListPage />}></Route>
                <Route exact path='/Shared' element={<InvoiceSharePreviewPage />} ></Route>
                <Route exact path='/InventoryList' element={<InventoryListPage />}></Route>
                <Route exact path='/*'  element={<PageNotFound />} ></Route>

              </>)
          }
          
        </Routes>
      </Router>
    );
  }
}
