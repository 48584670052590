import React from 'react'
import logo from '../../assets/Images/Picture1.png'
export default function Footercomp() {
  return (
    <div className="footerAlign">
    
    <label style={{color:"black"}}>Email:support.divyaliving.com</label>
     <img src={logo} alt='small logo'/> 
    <label style={{color:"black"}}>Website:www.divyaliving.com</label>
    </div>
  )
}

