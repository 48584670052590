import React from "react";
import InventoryList from "../Components/InventoryList";
//import PrimaryAppBar from "../Components/PrimarySearchAppBar";
import { read_cookie } from "sfcookies";
import axios from "axios"
import PasswordEntry from "../Components/PasswordEntry";
class InventoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      callbackvalue:false,
    };
    this.setStatusUpdate = this.setStatusUpdate.bind(this);
    this.StateUpdate = this.StateUpdate.bind(this);

    this.componentDidMount=this.componentDidMount(this);
  }
  componentDidMount()
  {
  
  }
  StateUpdate=(st)=> {
    this.setState({...this.state ,rows:st});
  }
  setStatusUpdate=(stat)=>{
    this.setState({...this.state ,callbackvalue:stat});
    
  }
   callbackfn=(password)=>
  {
    console.log(password+'Received 1');
    var myvalue =password==='divya123456';
      this.setStatusUpdate(myvalue);
      console.log(password+'Received ');
      if(myvalue){
        this.getInventory();
      }
  };
  getInventory() {
    const calcData = {
      id: 1,
    };

    axios
      .post(global.config.Server.serverName + "/Inventory", calcData)
      .then((res) => {
        //  console.log("Printing GetBakeCostList")
        //    console.log(res.data)
        //   this.updateState('bakingCostList',res.data)
        //     console.log("Printed GetBakeCostList")
        console.log(res.data);
        this.StateUpdate(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <div>
       {/*  <PrimaryAppBar /> */}

       { this.state.callbackvalue?
         <InventoryList  rows={this.state.rows}/>
        :
        <PasswordEntry callbackvalue={this.callbackfn} />}
         
        
      </div>
    );
  }
}
export default InventoryPage;
