

import axios from 'axios';
import React from 'react'

import DealList from './InvoiceComponents/DealList';

class InvoiceListSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = { data: [] }
        this.getdata = this.getdata.bind(this);
        this.getdata();
    }

    getdata() {
        axios
            .get(
                global.config.Server.serverName + "/deals"
            )
            .then((res) => {
                //  console.log("Printing GetBakeCostList")
                //    console.log(res.data)
                this.setState({
                    data: res.data,
                });
                //     console.log("Printed GetBakeCostList")
            })
            .catch((error) => {
                console.log(error);
            });
    }
    render() {

        return (

            <>
            <div style={{color:'black'}}>
                <DealList rows={this.state.data} /></div>
            </>
        );
    }
}
export default InvoiceListSelection;

