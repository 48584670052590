import * as React from "react";
import axios from 'axios'
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Button from '@mui/material/Button';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useNavigate } from "react-router-dom";
const columns = [
  { field: "hs_object_id", headerName: "ID", width: 40, visible: false },
  {
    field: "dealname",
    headerName: "Deal Name",
    width: 150,
    editable: false,
  },
  {
    field: "createdate",
    headerName: "Created Date",
    width: 150,
    editable: false,
    type: 'date',
    valueFormatter: (params) => {
      // first converts to JS Date, then to locale option through date-fns
      return new Date(params.value).toLocaleDateString('en-US');
    },
    // valueGetter for filtering
    valueGetter: (params) => {
      return new Date(params.value);
    }
    // valueGetter for filtering

  }

  ,
  {
    field: "amount",
    headerName: "amount",
    type: "number",
    width: 150,
    editable: false,
  },
  {
    field: "hs_lastmodifieddate",
    headerName: "lastmodifieddate",
    type: "string",
    width: 150,
    editable: false,
  }, {
    field: "closedate",
    headerName: "closedate",
    type: "string",
    width: 150,
    editable: false,
    visible: false
  }, {
    field: "dealstage",
    headerName: "dealstage",
    type: "string",
    width: 150,
    editable: false,
    visible: false
  },
  {
    field: "pipeline",
    headerName: "pipeline",
    type: "string",
    width: 150,
    editable: false,
    visible: false
  },
];

//const tempjson="[\"111111\",\"222222\",\"111111\",\"222222\"]"
export default function DealList({ rows }) {
  const [open, setOpen] = React.useState(false);
  const [quotes,setQuote]=React.useState('[]')
  const[dealid,setdealid]=React.useState('')
  const handleClickOpen = () => {
    setOpen(true);
  };
const navigate=useNavigate();
  const handleClose = () => {
    setOpen(false);
  };


    async function GetQuotData (dealno){
    const deal ={deal:dealno};
    await axios
      .post(
        global.config.Server.serverName + "/GetQuote",
        JSON.stringify(deal)
      )
      .then((res) => {
         setQuote( res.data.map((item)=> <FormControlLabel key={item.toString()} value={item.toString()} label={item.toString()} control={<Radio />} />  ));
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const handleSelectedRadio=(event)=>{
    //alert(event.target.value);
    navigate('/Preview?dealid='+dealid.toString() +'&quoteid='+event.target.value.toString())
};
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <h3>Select DealList</h3>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[20]}
        
        checkboxSelection={false}
        disableSelectionOnClick={false}
        isRowSelectable={()=>true}
        onRowClick={(param) => {
          GetQuotData(param.row.hs_object_id);
          setdealid(param.row.hs_object_id);
          handleClickOpen();
          }
        }

      />
      <Dialog open={open} onClose={handleClose} >
        <DialogTitle variant="H2">Select Quotes</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please Select Quots showing Down Below
          </DialogContentText>

          <RadioGroup name="use-radio-group" defaultValue="first" onChange={handleSelectedRadio}>
           {quotes}
          </RadioGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {/* <Button onClick={handleClose}>Proceed</Button> */}
        </DialogActions>
      </Dialog>



    </Box>
  );
}