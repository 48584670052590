import React from 'react';
import './App.css';
import "./assets/icofont.min.css";
import SiteRouter from './Modules/SiteRouter';

function App() {
  return (

    <div className="App">
         <SiteRouter />
    </div>
 );
}

export default App;
