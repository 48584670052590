
import { Button, CircularProgress } from '@mui/material';
import axios from 'axios'
import React from 'react'
import Dialog from "@mui/material/Modal";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import '../Popups/CustomerPopup.css'
import InvoicePreview from '../Components/InvoiceComponents/InvoicePreview'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

class InvoicePreviewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Linedata: null,
      CompanyData: null,
      ContactData: null,
      discount: null,
      tax: null,
      fees: null,
      deal: null,
      invoiceNo: "",
      invoicedate: null,
      email: "",
      loading: 0,
      opendialog: false,
      errormessage: "",
      finalUrl: ""
    };
    this.GetData = this.GetData.bind(this);

    //  this.SetExtaData = this.SetExtaData.bind(this);
    this.SetDialogState = this.SetDialogState.bind(this);
    this.SetLocalState = this.SetLocalState.bind(this); 
    this.componentDidMount = this.componentDidMount.bind(this);
    this.cleardata(props.quotno, props.dealID);
  }

  componentDidMount() {


  }
  cleardata(quotno, dealID) {

    this.GetData(quotno, 'line');
    this.GetData(quotno, 'company');
    this.GetData(quotno, 'discount');
    this.GetData(quotno, 'fees');
    this.GetData(quotno, 'tax');
    this.GetData(quotno, 'contact');
    this.GetData(dealID, 'deal');
  }
  // const [Linedata, setdataLinedata] = useState([]);
  // const [CompanyData, setdataCompanydata] = useState({});
  // const [ContactData, setdataContactdata] = useState({});
  // const [loading, setloading] = useState(false);
  GetData(quotid, option) {
    const quot =
    {
      quote: quotid,
      Option: option
    };

    axios.post(
      global.config.Server.serverName + "/FullQuote", JSON.stringify(quot))
      .then((res) => {

        if (option === 'line') { this.setState({ Linedata: res.data }); this.SetLocalState('loading', this.state.loading + 1); }
        else if (option === 'company') { this.setState({ CompanyData: res.data });  this.SetLocalState('loading', this.state.loading + 1);}
        else if (option === 'contact') { this.setState({ ContactData: res.data }); this.SetLocalState('loading', this.state.loading + 1); }
        else if (option === 'discount') { this.setState({ discount: res.data }); this.SetLocalState('loading', this.state.loading + 1); }
        else if (option === 'tax') { this.setState({ tax: res.data }); this.SetLocalState('loading', this.state.loading + 1); }
        else if (option === 'fees') { this.setState({ fees: res.data }); this.SetLocalState('loading', this.state.loading + 1); }
        else if (option === 'deal') { this.setState({ deal: res.data }); this.SetLocalState('loading', this.state.loading + 1); }
        else {

        }
        // console.log(res.data);
        // alert(JSON.stringify(res.data));
        //this.setState({ loading: this.state.loading-1 });
      })
  }


  SetExtaData(stateName, stateValue) {
    this.setState({ extra: { ...this.state.extra, [stateName]: stateValue } });
  }
  SetLocalState(stateName, stateValue) {
    this.setState({ [stateName]: stateValue });
  }
  SetDialogState(dialogState) {
    this.setState({ opendialog: dialogState })
  }
  checkLineData(line_data)
  {

    const len=line_data.length;
    console.log(len);
    for(let q=0;q<len;q++)
    {
      console.log(JSON.stringify(line_data[q]?.product?.sku));
      if(line_data[q]?.product?.sku===undefined || line_data[q]?.product ===null||line_data[q]?.product?.sku.toString()==="")
      {
        return false;
      }

    }
    return true;
  }
  render() {

    const { Linedata,
      CompanyData,
      ContactData,
      discount,
      tax,
      fees,
      deal,
      invoiceNo,
      invoicedate,
      email,
      loading,
      opendialog,
      errormessage, finalUrl } = this.state;
    const GenerateInvoice = (event) => {
      //alert(extra.invoicedate.toString());
      let dataq = {
        linesitem: Linedata,
        Companies: CompanyData,
        Contacts: ContactData,
        Discounts: discount,
        Feeses: fees,
        Taxes: tax,
        Deals: deal,
        invNo: invoiceNo,
        date: invoicedate.$d.toISOString(),
        emails: email
      };
      axios.post(
        global.config.Server.serverName + "/InvoiceAction", JSON.stringify(dataq))
        .then((res) => {
          console.log(res.data['url']);
          this.SetLocalState('finalUrl', global.config.Server.url + "Shared?uuid=" + res.data['url'])
          this.SetDialogState(false);
        });


    };


    return (

      <div>

        {

          this.state.loading <= 6 ? (<div><h3>Loading.....{this.state.loading} Out of 6</h3> <CircularProgress /></div>) :
            (
              this.checkLineData(Linedata)?
            
            (
            this.state.finalUrl===""?
            
            (<>
              <InvoicePreview line={Linedata}
                company={CompanyData}
                contact={ContactData}
                discount={discount}
                tax={tax}
                fees={fees}
                deals={deal}
                extra={''}
                callRefresh={() => this.cleardata()} />
              <p variant="h1">{this.state.finalUrl}</p>
              <Button variant="contained" onClick={(event) => { event.preventDefault(); this.SetDialogState(true); }}>Generate Invoice</Button>
              <Dialog open={this.state.opendialog}  >
                <div className="popup-OuterContainer">
                  <div className="popup">
                    <Box className='dialog-out'>
                      <h3 className='customh3' >Invoice Data</h3>
                      <div className='dialog-out-in'>
                        <TextField id="outlined-search" label="Invoice Number" size="small"
                          onChange={(newValue) => {
                            this.SetLocalState('invoiceNo', newValue.target.value);
                            // alert(newValue.target.value);
                          }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label="Invoice Date"
                            inputFormat="DD-MM-YYYY"
                            value={this.state.invoicedate}
                            onChange={(newValue) => {
                              this.SetLocalState('invoicedate', newValue);

                            }}
                            renderInput={(params) => <TextField {...params} size="small" />}
                          />
                        </LocalizationProvider>
                        <TextField id="outlined-search" label="email" size="small"
                          onChange={(newValue) => {
                            this.SetLocalState('email', newValue.target.value);
                          }} />
                        {this.state.errormessage}

                        <div className='buttonGroup'>
                          <Button variant="contained" size="small" onClick={(event) => {
                            event.preventDefault();
                            if (this.state.invoiceNo === "") {
                              this.SetLocalState('errormessage', 'Invalid Invoice No');
                            }
                            else if (this.state.invoicedate == null) {
                              this.SetLocalState('errormessage', 'Invalid Invoice date');
                            }
                            else {
                              this.SetLocalState('errormessage', '');
                              GenerateInvoice();
                            }


                          }}>Create </Button>
                          <Button variant="contained" size="small" id="btn_invoice" onClick={(event) => { event.preventDefault(); this.SetDialogState(false); }}>Cancel </Button>
                        </div>
                      </div>
                    </Box>
                  </div>
                </div>
              </Dialog >
            </>):
            (<>
            <p>Invoice URL for sharing</p>
            <h1>{this.state.finalUrl}</h1>
            </>)
            
            ):
            (<h3>Empty SKU or Product doesn't exist in the HubSpot product liberary</h3>)
            )}
      </div>
    )
  }
}
export default InvoicePreviewPage;