import React from "react";
import Login from "../Components/Login";
import './LoginPage.css'

 const LoginPage = () => {
  return (

    <div className="login-header">
      <Login />
    </div>

  );
};

export default  LoginPage;