import { GetJSON } from "../Modules/JsonRequest";
import { bake_cookie,delete_cookie } from 'sfcookies';

export  async  function GetUserInfo (username1,password1){
    
    const str={'username':username1,'password':password1};
    console.log(username1 +password1)
     const result=  await GetJSON(global.config.Server.serverName+"/GetUserInfo",JSON.stringify(str));
     console.log('Result for login' + JSON.stringify(result))
     bake_cookie( 'AUTH',username1===result.Data[0]?.['Username']?result.Data[0]?.['Name']:"");
     bake_cookie( 'UID',result.Data[0]?.['ID']);
     bake_cookie( 'ID',result.Data[0]?.['UID']);
      if(username1===result.Data[0]?.['Username'])
      {window.location.reload(false);}
    console.log("Data from function :"+JSON.stringify(result.Data));
    return result;
   }

   export function  UserLogout()
   {
        delete_cookie('AUTH');
        delete_cookie( 'UID');
        delete_cookie( 'ID');
        window.location.reload(false);
   }