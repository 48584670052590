import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';




function ccyFormat(num) {
  return `${"$" + num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(Product, name, desc, qty, unit) {
  const price = priceRow(qty, unit);

  //  console.log(Product.toString());
  return { Product, name, desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}





export default function SpanningTable({ rowss, discount, fees, tax }) {
  const taxrender =tax!==null&& tax?.error!==undefined;
  const disrender =discount!==null && discount?.error!==undefined;
  const feerender = fees!==null && fees?.error !== undefined ;
  var rows = [];
  //.map(({items,i})=>rows.push(createRow('5655','Paperclips (Box)', 100, 1.15)));
console.log(tax);
  const rowlength = rowss?.length == null ? 0 : rowss.length;
  //alert(rowlength)
  for (let i = 0; i < rowlength; i++) {
    rows.push(
      createRow(
        rowss[i]?.product?.sku?.toString(),
        rowss[i]?.product?.name?.toString(),
        rowss[i]?.product?.description?.toString(),
        parseFloat(rowss[i]['quantity']),
        parseFloat(rowss[i]['amount'])
      )
    );
  }
  const invoiceSubtotal = subtotal(rows);
  console.log(taxrender.toString() + ': taxrender' +
  disrender.toString() + ':disrender' +
  feerender.toString() + ':feerender' );
  const taxvalue= isNaN(tax?.hs_value)?"0":tax?.hs_value;
  const discValue=isNaN(discount?.hs_value)?"0":discount?.hs_value;
  const feesValue=isNaN(fees?.hs_value)?"0":fees?.hs_value;
  const invoiceTaxes = tax!==null&& tax?.error===undefined ? (tax?.hs_type !== "FIXED" ? (taxvalue * invoiceSubtotal) : parseFloat(taxvalue)) : 0.0;
  const invoiceDiscount = disrender !==null&& disrender?.error===undefined ? (discount?.hs_type !== "FIXED" ? (discValue * invoiceSubtotal) : parseFloat(discValue)) : 0.0;
  const invoicefees = feerender !==null&& feerender?.error===undefined ? (fees?.hs_type !== "FIXED" ? (feesValue * invoiceSubtotal) : parseFloat(feesValue)) : 0.0;
  const invoiceTotal =  invoiceTaxes + invoiceSubtotal + invoicefees - invoiceDiscount;
  //rowss[0]['properties']['quantity']
  //
  console.log(invoiceTaxes.toString() + ',' +
    invoiceSubtotal.toString() + ',' +
    invoicefees.toString() + ',' +
    invoiceDiscount.toString());
  return (
    <TableContainer component={Paper} >
      <Table sx={{
        minWidth: 700, [`& .${tableCellClasses.root}`]: {
          borderBottom: "none",
          minHeight: "40px", height: "40px", padding: "5px 0px 5px 5px"
        }
      }} aria-label="spanning table" >
        <TableHead >
          <TableRow className='headercolour' style={{ height: 30, padding: "0px 0px 0px 0px" }}>
            <TableCell style={{ height: 30, padding: "10px 0px 10px 10px", textAlign: 'center' }}><span className='headercolour'>Product</span></TableCell>
            <TableCell style={{ height: 30, padding: "10px 0px 10px 10px", textAlign: 'center' }}><span className='headercolour'>Desc</span></TableCell>
            <TableCell align="right" style={{ height: 30, padding: "10px 0px 10px 10px", textAlign: 'center' }}><span className='headercolour'>Qty.</span></TableCell>
            <TableCell align="right" style={{ height: 30, padding: "10px 0px 10px 10px", textAlign: 'center' }}><span className='headercolour'>Price</span></TableCell>
            <TableCell align="right" style={{ height: 30, padding: "10px 0px 10px 10px", textAlign: 'center' }}><span className='headercolour'>Sum</span></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.desc}>
              <TableCell>{row.Product}</TableCell>
              <TableCell>{row.name}<br />{row.desc}</TableCell>
              <TableCell align="right">{row.qty}</TableCell>
              <TableCell align="right">{"$" + row.unit}</TableCell>
              <TableCell align="right">{ccyFormat(row.price)}</TableCell>
            </TableRow>
          ))}

          <TableRow style={{ borderTop: "1px solid" }} >
            <TableCell rowSpan={1} style={{ padding: "1px 0px 0px 1px" }} />
            <TableCell rowSpan={3} style={{ padding: "1px 0px 0px 1px" }} />
            <TableCell style={{ padding: "1px 0px 0px 1px" }} colSpan={1} align="right">Subtotal</TableCell>
            <TableCell style={{ padding: "1px 0px 0px 1px" }} colSpan={2} align="right">{ccyFormat(invoiceSubtotal)}</TableCell>
          </TableRow>
          {invoiceDiscount !== 0.0 ? (<TableRow style={{ padding: "1px 0px 0px 1px" }}>
            <TableCell rowSpan={1} />
            <TableCell style={{ padding: "1px 0px 0px 1px" }} colSpan={1} align="right">{"discount" && discount?.hs_label}</TableCell>
            <TableCell style={{ padding: "1px 0px 0px 1px" }} align="right">{discount?.hs_type !== "FIXED" ? `${(discount?.hs_value * 100).toFixed(0)} %` : ""}</TableCell>
            <TableCell style={{ padding: "1px 0px 0px 1px" }} align="right">{"-" + ccyFormat(invoiceDiscount)}</TableCell>
          </TableRow>) : (<></>)}

          {invoiceTaxes !== 0.0 ? (<TableRow style={{ padding: "1px 0px 0px 1px" }}>
            <TableCell rowSpan={1} style={{ padding: "1px 0px 0px 1px" }} />
            <TableCell style={{ padding: "1px 0px 0px 1px" }} colSpan={1} align="right">{"Tax" && tax?.hs_label}</TableCell>
            <TableCell style={{ padding: "1px 0px 0px 1px" }} align="right">{tax?.hs_type !== "FIXED" ? `${(tax?.hs_value * 100).toFixed(0)} %` : ""}</TableCell>
            <TableCell style={{ padding: "1px 0px 0px 1px" }} align="right">{ccyFormat(invoiceTaxes)}</TableCell>
          </TableRow>) : (<></>)}
          {invoicefees !== 0.0 ? (<TableRow style={{ padding: "1px 0px 0px 1px" }}>
            <TableCell style={{ padding: "1px 0px 1px 1px" }} rowSpan={1} colSpan={2} />
            <TableCell style={{ padding: "1px 0px 1px 1px" }} colSpan={1} align="right">{"Fees" && fees?.hs_label}</TableCell>
            <TableCell style={{ padding: "1px 0px 1px 1px" }} align="right">{fees?.hs_type !== "FIXED" ? `${(fees?.hs_value * 100).toFixed(0)} %` : ""}</TableCell>
            <TableCell style={{ padding: "1px 0px 1px 1px" }} align="right">{ccyFormat(invoicefees)}</TableCell>
          </TableRow>) : (<></>)}

          <TableRow style={{ padding: "1px 0px 0px 1px" }}>
            <TableCell style={{ padding: "1px 0px 1px 1px" }} rowSpan={1} colSpan={2} />
            <TableCell style={{ padding: "1px 0px 1px 1px" }} colSpan={1} align="right">Total</TableCell>
            <TableCell style={{ padding: "1px 0px 1px 1px" }} colSpan={2} align="right">{ccyFormat(invoiceTotal)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}