import React from "react";

class PageNotFound extends React.Component {
  render() {
    return (
      <div>
        <h1>404 Error</h1>
        <h1>Page Not Found</h1>
      </div>
    );
  }
}

export default PageNotFound;
