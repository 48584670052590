import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({dialogTitle,dialogContent,buttonText,parentClose,open}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={parentClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle  variant="h4">
          <b>{dialogTitle}</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           {dialogContent}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={parentClose}>{buttonText}</Button>
         
        </DialogActions>
      </Dialog>
    </div>
  );
}