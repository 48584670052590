import React from "react";
import "./CustomerComp.css";
import { Box, Stack, TextField, Button, Autocomplete } from "@mui/material";
import AlertDialog from "./AlertDialogue";
import { read_cookie } from "sfcookies";
import data from "./../Global/country.json";
import axios from "axios";
import { NavigateToPath } from "../Modules/JsonRequest";
class CustomerComp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      phone: "",
      addressLine1: "",
      addressLine2: "",
      description: "",
      pincode: "",
      countryId: "",
      openAlertDialog: false,
      alertMessage: "",
      dialogTitle: "Error",
    };
    this.updateStates = this.updateStates.bind(this);
  }
 
  setAlertDialogOff = () => {
   
    this.updateStates("openAlertDialog", false);
    if(this.state.dialogTitle==="Information")
    {
     NavigateToPath("/Calculator");
     //this.props.navigation.navigate('/Calculator');
    }
  };
  setAlertDialogOn = () => {
    this.updateStates("openAlertDialog", true);
  };
  updateStates(stateName, stateValue) {
    console.log("working" + stateName + " " + stateValue.toString());
    this.setState({
      [stateName]: stateValue,
    });
  }
  
  tryToInsert = (event) => {
    event.preventDefault();
    this.updateStates("dialogTitle", "Error");
    if (this.state.firstName === "") {
      this.updateStates("alertMessage", "Enter A valid FirstName ");
      this.setAlertDialogOn();
    } else if (this.state.companyName === "") {
      this.updateStates("alertMessage", "Enter A valid Company Name ");
      this.setAlertDialogOn();
    } else if (this.state.addressLine1 === "") {
      this.updateStates("alertMessage", "Enter A valid Address ");
      this.setAlertDialogOn();
    } else if (this.state.email === "") {
      this.updateStates("alertMessage", "Enter A valid Email ");
      this.setAlertDialogOn();
    } else if (this.state.phone === "") {
      this.updateStates("alertMessage", "Enter A valid PhoneNo ");
      this.setAlertDialogOn();
    } else if (this.state.pincode === "") {
      this.updateStates("alertMessage", "Enter A valid Pincode ");
      this.setAlertDialogOn();
    } else if (this.state.countryId === "") {
      this.updateStates("alertMessage", "Enter A valid Country ");
      this.setAlertDialogOn();
    } else {
      const customerInfo = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        companyName: this.state.companyName,
        email: this.state.email,
        phone: this.state.phone,
        addressLine1: this.state.addressLine1,
        addressLine2: this.state.addressLine2,
        description: this.state.description,
        pincode: this.state.pincode,
        countryId: this.state.countryId["CountryId"],
        UserID: read_cookie("UID"),

      };
      axios
        .post(global.config.Server.serverName + "/addCustomer", customerInfo)
        .then((res) => {
          this.updateStates("alertMessage", res.data.toString());
          this.updateStates("dialogTitle", "Information");
          this.setAlertDialogOn();
          this.props.myCallback();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  
  render() {
 //   const navigate=useNavigate();
    return (
      <Box className="form-wrapper" container verient="form">
        <h2>Customer Registration</h2>
        <Stack className="StackClass" spacing={2}>
          <div className="buttonContainer">
            <TextField
              className="outlined-basic"
              label="First Name"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              required
              onChange={(e) => {
                this.updateStates("firstName", e.target.value);
              }}
            />
            <div className="space" />
            <TextField
              className="outlined-basic"
              label="Last Name"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => {
                this.updateStates("lastName", e.target.value);
              }}
            />
          </div>
          <TextField
            className="outlined-basic"
            label="Company Name"
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            required
            onChange={(e) => {
              this.updateStates("companyName", e.target.value);
            }}
          />
          <TextField
            className="outlined-basic"
            label="Address Line 1"
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            required
            onChange={(e) => {
              this.updateStates("addressLine1", e.target.value);
            }}
          />
          <TextField
            className="outlined-basic"
            label="Address Line 2"
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            onChange={(e) => {
              this.updateStates("addressLine2", e.target.value);
            }}
          />
          <div className="buttonContainer">
            <TextField
              className="outlined-basic"
              label="Email"
              type="email"
              variant="outlined"
              size="small"
              fullWidth
              required
              onChange={(e) => {
                this.updateStates("email", e.target.value);
              }}
            />
            <div className="space" />
            <TextField
              className="outlined-basic"
              label="Phone"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              required
              onChange={(e) => {
                this.updateStates("phone", e.target.value);
              }}
            />
          </div>
          <div className="buttonContainer">
            <TextField
              className="outlined-basic"
              label="Zipcode"
              type="text"
              variant="outlined"
              size="small"
              fullWidth
              required
              onChange={(e) => {
                this.updateStates("pincode", e.target.value);
              }}
            />
            <div className="space" />
            <Autocomplete
              size="small"
              fullWidth={true}
              onChange={(event, newValue) => {
                console.log("new Value=" + newValue);
               
                if (newValue === null) {
                  return;
                }
                this.updateStates("countryId", newValue);
                //   console.log("image is this "+this.state.productInfo[0]['PImage'])
              }}
              options={data}
              autoHighlight
              getOptionLabel={(option) => option.Name}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${option.Code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.Code.toLowerCase()}.png 2x`}
                    alt=""
                  />
                  {option.Name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  autowidth={"true"}
                  label="Choose a country"
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </div>
          <div className="buttonContainer">
            <Button
              variant="contained"
              onClick={(event) => {
                this.tryToInsert(event);
                
              //  navigate('/Calculator');
              //  console.log(this.state);
              }}
            >
              <b>Save</b>
            </Button>
            <div className="space" />
            <Button variant="outlined" onClick={()=>this.props.myCallback()}>
              <b>Close</b>
            </Button>
          </div>
        </Stack>
        <AlertDialog
          buttonText={"Ok"}
          dialogContent={this.state.alertMessage}
          dialogTitle={this.state.dialogTitle}
          open={this.state.openAlertDialog}
          parentClose={this.setAlertDialogOff}
        />
      </Box>
    );
  }
}
export default CustomerComp;
