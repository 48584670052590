import React from "react";
import InventoryList from "../Components/InventoryList";
import PrimaryAppBar from "../Components/PrimarySearchAppBar";

class InventoryListPage extends React.Component
{
    render()
    {
        return(
          <> 
           <PrimaryAppBar /> 
          <InventoryList />
           </>
        )
    }
}
export default InventoryListPage;