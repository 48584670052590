import React from "react";
 
import "./Calculators.css";
import PriceCalculator from "../Components/PriceCalculator";
import PrimaryAppBar from "../Components/PrimarySearchAppBar";
class Calculator extends React.Component {
  render() {
    return (
      <div>
        <PrimaryAppBar />
        <div className="calc-header">
        <PriceCalculator />
      </div></div>
    );
  }
}

export default Calculator;
