import * as React from "react";
import { Box } from "@mui/system";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import "./PriceCalculatorStyle.css";
import axios from "axios";
import PreviewSection from "./PreviewSection";
import "../Global/variables";
import { read_cookie } from "sfcookies";
import CustomerPopup from "../Popups/CustomerPopup";
import { GrAddCircle } from "react-icons/gr";
// import { useNavigate } from "react-router-dom";
//import { Link } from "@mui/icons-material";
//import CustomerPage from '../Pages/CustomerPage'
class PriceCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      indx: 0,
      width_inch: 0,
      width_inch_helper_text: "",
      width_feet: 0,
      width_feet_helper_text: "",
      length_inch: 0,
      length_inch_helper_text: "",
      length_feet: 0,
      length_feet_helper_text: "",
      Products: [],
      finishingTypeList: [],
      bindingTapeList: [],
      bakingCostList: [],
      splist:[],
      finishingType: "",
      finishingType_helper_text: "",
      product: "",
      product_helper_text: "",
      bindingTape: "",
      bindingTape_helper_text: "",
      bakingCost: "",
      spitem: "",
      preparedBy_helper_text: "",
      bakingCost_helper_text: "",
      sPName_helper_text:"",
      customer_helper_text: "",
      result: [],
      productInfo: [],
      hoverImage: "",
      BakeCostResult: 0.0,
      customer: "",
      customerList: [],
      openCustPopup: false,
      preparedBy: "",
      selectedProduct:{}
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.updateState = this.updateState.bind(this);
    this.GetCustomer();
    this.GetProducts();
    this.GetFinishingType();
    // this.GetBindingType();
    this.GetBakeingList();
    this.GetSPList();
  }
  handleImageChange(e) {
    this.updateState("hoverImage", e);
    // console.log("changeing image to " + e);
  }
  handleInputChange(e) {
    this.setState({
      // spread in previous state with object spread operator
      [e.target.name]: e.target.value,
    });

    this.setState({
      result: [],
    });
    this.setState({
      BakeCostResult: 0,
    });
    //console.log(e.target.name)
  }
  updateState(statName, statValue) {
    //console.log("State " + statName);
    this.setState({
      [statName]: statValue,
    });
    //console.log(statName);
    //console.log(" " + statName + "  " + statValue);
    if (
      !(
        statName === "result" ||
        statName === "bakingCost" ||
        statName === "BakeCostResult" ||
        statName.includes("helper")
      )
    ) {
      this.updateState("result", []);
    }
    if (
      statName === "bakingCost" ||
      statName === "width_inch" ||
      statName === "width_feet" ||
      statName === "length_inch" ||
      statName === "length_feet"
    ) {
      this.updateState("BakeCostResult", 0);
    }
  }
  calculate() {
    // console.log(
    //   "width_inch=%d,width_feet=%d,length_inch=%d,length_feet=%d,sqft=%d",
    //   this.state.width_inch,
    //   this.state.width_feet,
    //   this.state.length_inch,
    //   this.state.length_feet,
    //   100
    // );
  }

  GetProducts = () => {
    //console.log(this.state);

    axios
      .post(
        global.config.Server.serverName + "/GetProducts",
        JSON.stringify(this.state)
      )
      .then((res) => {
        //  console.log("GetProducts")
        //    console.log(res.data)
        this.updateState("Products", res.data);
        //   console.log("Printed GetProducts")
      })
      .catch((error) => {
        console.log(error);
      });
  };
  GetFinishingType = () => {
    // console.log(this.state);

    axios
      .post(
        global.config.Server.serverName + "/GetEdgeFinishing",
        JSON.stringify(this.state)
      )
      .then((res) => {
        //   console.log("Printing GetEdgeFinishing")
        //z     console.log(res.data)
        this.updateState("finishingTypeList", res.data);
        //     console.log("Printed GetEdgeFinishing")
      })
      .catch((error) => {
        console.log(error);
      });
  };
  GetBindingType = (finData) => {
    axios
      .post(
        global.config.Server.serverName + "/GetBindingTapeList",
        JSON.stringify(finData)
      )
      .then((res) => {
        // console.log(finData);
        //    console.log("Printing GetBindingTapeList")
        //   console.log(res.data);
        this.updateState("bindingTapeList", res.data);
        //    console.log("Printed GetBindingTapeList")
      })
      .catch((error) => {
        console.log(error);
      });
  };
  GetCustomer = () => {
    const userinfo = {
      userid: read_cookie("UID"),
    };
    axios
      .post(global.config.Server.serverName + "/getCustomers", userinfo)
      .then((res) => {
        //console.log(res.data);
        // if(res.data===""){
        //   useNavigate('/Customers');
        // }
        this.updateState("customerList", res.data ?? []);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  GetBakeingList = () => {
    // console.log(this.state)
    // if(this.state.edgeFinishingType==="")
    // {
    //   this.updateState('bindingTapeList',[])
    //   return;
    // }

    axios
      .post(
        global.config.Server.serverName + "/GetBakeCostList",
        JSON.stringify(this.state)
      )
      .then((res) => {
        //  console.log("Printing GetBakeCostList")
        //    console.log(res.data)
        this.updateState("bakingCostList", res.data);
        //     console.log("Printed GetBakeCostList")
      })
      .catch((error) => {
        console.log(error);
      });
  };
  GetSPList = () => {
    // console.log(this.state)
    // if(this.state.edgeFinishingType==="")
    // {
    //   this.updateState('bindingTapeList',[])
    //   return;
    // }

    axios
      .post(
        global.config.Server.serverName + "/GetSPDetails",
        JSON.stringify(this.state)
      )
      .then((res) => {
        //  console.log("Printing GetBakeCostList")
        //    console.log(res.data)
        this.updateState("splist", res.data);
        //     console.log("Printed GetBakeCostList")
      })
      .catch((error) => {
        console.log(error);
      });
  };
  GetProductInfo = (vals) => {
    axios
      .post(
        global.config.Server.serverName + "/GetProductInfo",
        JSON.stringify(vals)
      )
      .then((res) => {
        this.updateState("productInfo", res.data);
        // console.log(JSON.stringify(res.data));
      })

      .catch((error) => {
        console.log(error);
        this.updateState("productInfo", []);
      });
  };

  calculatePrice = () => {
    const {selectedProduct}=this.state;
    const actualval=this.state.width_feet*12 +Number(this.state.width_inch);
    if (this.state.customer === "") {
      this.updateState("customer_helper_text", "Select A Customer First");
      return;
    }
    if (this.state.preparedBy === "") {
      this.updateState("preparedBy_helper_text", "Enter Who is Preparing");
      return;
    } else {
      this.updateState("customer_helper_text", "");
    }
    if (this.state.product === "") {
      this.updateState("product_helper_text", "Select A Product ");
      return;
    } else {
      this.updateState("product_helper_text", "");
    }
    if (this.state.width_feet === "") {
      this.updateState("width_feet_helper_text", "Width inch cannot be empty");
      return;
    } else {
      this.updateState("width_feet_helper_text", "");
    }
    if (this.state.width_inch === "") {
      this.updateState("width_inch_helper_text", "Width feet cannot be empty");
      return;
    } else if (this.state.width_inch >= 12) {
      this.updateState("width_inch_helper_text", "inch cannot be more than 11");
      return;
    }
    else if (actualval > selectedProduct.MaxWidth ) {
      this.updateState("width_inch_helper_text", `Max Length ${Math.floor(selectedProduct.MaxWidth/12)}' ${selectedProduct.MaxWidth%12}"`);
      return;
    }
    else {
      this.updateState("width_inch_helper_text", "");
    }
    if (this.state.length_feet === "") {
      this.updateState(
        "length_feet_helper_text",
        "length feet cannot be empty"
      );
      return;
    } else {
      this.updateState("length_feet_helper_text", "");
    }
    if (this.state.length_inch === "") {
      this.updateState(
        "length_Inch_helper_text",
        "length Inch cannot be empty"
      );
      return;
    } else if (this.state.length_inch >= 12) {
      this.updateState(
        "length_Inch_helper_text",
        "inch cannot be more than 11"
      );
      return;
    } else {
      this.updateState("length_Inch_helper_text", "");
    }
    if (this.state.finishingType === "") {
      this.updateState("finishingType_helper_text", "Select A Finishing Type");
      return;
    } else {
      this.updateState("finishingType_helper_text", "");
    }
    if (this.state.bakingCost === "") {
      this.updateState("bakingCost_helper_text", "Select A Baking cost");
      return;
    } else {
      this.updateState("bakingCost_helper_text", "");
    }
    const calcData = {
      width_inch: this.state.width_inch,
      width_feet: this.state.width_feet,
      length_inch: this.state.length_inch,
      length_feet: this.state.length_feet,
      finishingType: this.state.finishingType,
      product: this.state.product,
      bindingTape: this.state.bindingTape,
      customer: this.state.customer,
      preparedBy: this.state.preparedBy,
      bakingCost: this.state.bakingCost,
      spitem:this.state.spitem,
      UserID: read_cookie("UID"),
    };

    axios
      .post(global.config.Server.serverName + "/GetCalculatedValue", calcData)
      .then((res) => {
        //  console.log("Printing GetBakeCostList")
        //    console.log(res.data)
        //   this.updateState('bakingCostList',res.data)
        //     console.log("Printed GetBakeCostList")
        this.updateState("result", res.data);

      })
      .catch((error) => {
        console.log(error);
      });
  };
 
  handlePopupOpen = () => {
    this.updateState("openCustPopup", true);
  };
  handlePopupClose = () => {
    this.updateState("openCustPopup", false);
    this.GetCustomer();
  };
  render() {
    return (
      <>
        <Box
          width="920px"
          border="2"
          borderRadius="10px"
          boxShadow="8"
          padding="5px"
          paddingTop="1px"
        >
          <Typography
            variant="h4"
            marginBottom={"px"}
            color={"primary"}
            marginTop={"20px"}
          >
            <b> Rugs Price Calculator</b>
          </Typography>
          <div>
            <Grid container spacing={0.5} variant="form" marginTop={"20px"}>
              <Grid item xs={6} className="alignCenter">
                <Box
                  varient="container"
                  width="450px"
                  border="2"
                  borderRadius="3"
                  boxShadow="0"
                  padding="10px"
                  paddingTop="1px"
                >
                  <Grid container spacing={0.5} variant="form">
                    {/* ---------------------------------------------------------------- */}
                    <Grid item xs={4} className="alignCenter">
                      <Typography variant="body1" color={"primary"}>
                        {" "}
                        Customer Name
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Autocomplete
                        onChange={(event, newValue) => {
                          this.updateState("customer", newValue);
                          if (newValue === null) {
                            return;
                          }
                          console.log(newValue);
                          //this.GetProductInfo(newValue);
                          //   console.log("image is this "+this.state.productInfo[0]['PImage'])
                        }}
                        autowidth={1}
                        options={this.state.customerList}
                        autoHighlight
                        getOptionLabel={(option) => option.FirstName}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.id}>
                              {option.FirstName}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={this.state.customer_helper_text}
                            label=""
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <div className="add-buttonContainer">
                        {" "}
                        <GrAddCircle
                          onClick={() => this.handlePopupOpen()}
                          size={"100%"}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={4} className="alignCenter">
                      <Typography variant="body1" color={"primary"}>
                        {" "}
                        PreparedBy
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <TextField
                        size="small"
                        label=""
                        fullWidth
                        name="preparedBy"
                        value={this.state.preparedBy}
                        onChange={this.handleInputChange}
                        helperText={this.state.preparedBy_helper_text}
                      />
                    </Grid>
                    <Grid item xs={4} className="alignCenter">
                      <Typography variant="body1" color={"primary"}>
                        {" "}
                        Select Product
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Autocomplete
                        onChange={(event, newValue) => {
                          //console.log("new Value=" + newValue);
                          this.updateState("product", newValue);
                          if (newValue === null) {
                            return;
                          }
                          this.GetProductInfo(newValue);
                          console.log(newValue);
                          this.updateState("selectedProduct",newValue)
                          //   console.log("image is this "+this.state.productInfo[0]['PImage'])
                        }}
                        autowidth={1}
                        options={this.state.Products}
                        autoHighlight
                        getOptionLabel={(option) => option.Product}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={this.state.product_helper_text}
                            label=""
                            size="small"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={4} className="alignCenter">
                      <Typography variant="body1" color={"primary"}>
                        {" "}
                        Enter Width
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        label=""
                        name="width_feet"
                        defaultValue={0}
                        onChange={this.handleInputChange}
                        helperText={this.state.width_feet_helper_text}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <sub>Feet</sub>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        label=""
                        name="width_inch"
                        defaultValue={0}
                        helperText={this.state.width_inch_helper_text}
                        onChange={this.handleInputChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end" size="10">
                              <sub>Inch</sub>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={4} className="alignCenter">
                      <Typography variant="body1" color={"primary"}>
                        {" "}
                        Enter Length
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        label=""
                        name="length_feet"
                        helperText={this.state.length_feet_helper_text}
                        onChange={this.handleInputChange}
                        defaultValue={0}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <sub>Feet</sub>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        size="small"
                        label=""
                        name="length_inch"
                        helperText={this.state.length_inch_helper_text}
                        defaultValue={0}
                        onChange={this.handleInputChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <sub>Inch</sub>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>

                    <Grid item xs={4} className="alignCenter">
                      <Typography variant="body1" color={"primary"}>
                        {" "}
                        Edge Finishing
                      </Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <Autocomplete
                        onChange={(event, newValue) => {
                          this.updateState("finishingType", newValue);
                          this.GetBindingType(newValue);
                          this.updateState("bindingTape", "");
                        }}
                        autowidth={1}
                        options={this.state.finishingTypeList}
                        autoHighlight
                        getOptionLabel={(option) => option.Ftype}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={this.state.finishingType_helper_text}
                            label=""
                            size="small"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={4} className="alignCenter">
                      <Typography variant="body1" color={"primary"}>
                        Border Material
                      </Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <Autocomplete
                        onChange={(event, newValue) => {
                          this.updateState("bindingTape", newValue);
                        }}
                        id="test"
                        autowidth={1}
                        options={this.state.bindingTapeList}
                        autoHighlight
                        getOptionLabel={(option) => option.Tape}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={this.state.bindingTape_helper_text}
                            label=""
                            size="small"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={4} className="alignCenter">
                      <Typography variant="body1" color={"primary"}>
                        Pad/Backing
                      </Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <Autocomplete
                        onChange={(event, newValue) => {
                          this.updateState("bakingCost", newValue);
                        }}
                        autowidth={1}
                        menuStyle={{maxHeight:"20vh"}}
                        options={this.state.bakingCostList}
                        autoHighlight
                        getOptionLabel={(option) => option.Bcost}
                        
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={this.state.bakingCost_helper_text}
                            label=""
                            size="small"
                          />
                        )}
                      />
                    </Grid>
{/* ---------------------------------------------------------*/}
<Grid item xs={4} className="alignCenter">
                      <Typography variant="body1" color={"primary"}>
                      Stain Protection
                      </Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <Autocomplete
                        onChange={(event, newValue) => {
                          this.updateState("spitem", newValue);
                        }}
                        autowidth={1}
                        menuStyle={{maxHeight:"20vh"}}
                        options={this.state.splist}
                        autoHighlight
                        getOptionLabel={(option) => option.SPName}
                        
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            helperText={this.state.sPName_helper_text}
                            label=""
                            size="small"
                          />
                        )}
                      />
                    </Grid>
{/* ---------------------------------------------------------  */}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h4" color={"primary"}>
                      <div className="PriceLables">
                     <normal> Product Cost  </normal>
                      <b>
                        $
                        {Math.round(parseFloat(this.state.result.FinalPrice ?? 0))}
                      </b></div>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h4" color={"primary"}>
                      <div className="PriceLables">
                     <normal> Rugpad Cost  </normal>
                      <b>
                        $
                        {Math.round(parseFloat(this.state.result.BackingCost ?? 0))}
                      </b>
                      </div>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h4" color={"primary"}>
                      <div className="PriceLables">
                     <normal> Stain Protection Cost  </normal>
                      <b>
                        $
                        {Math.round(parseFloat(this.state.result.stainProtectionCost ?? 0))}
                      </b>
                      </div>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                      <Button
                        variant="contained"
                        type="button"
                        onClick={this.calculatePrice}
                        disabled={this.state.customerName === ""}
                      >
                        calculate
                      </Button>
                    </Grid>
                </Box>
              </Grid>
              <Grid item xs={6} className="alignCenter" width={450}>
                <PreviewSection productInfo={this.state.productInfo} />
              </Grid>
            </Grid>
            <CustomerPopup
              open={this.state.openCustPopup}
              handlePopupClose={this.handlePopupClose}
            />
          </div>
        </Box>
      </>
    );
  }
}
export default PriceCalculator;
