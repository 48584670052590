import React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

const CalculationHistory =({rows})=>  {
  


 
    const columns = [
      { field: 'id', headerName: 'ID', width: 90 },
      {
        field: "Product",
        headerName: "Product",
        width: 150,
        editable: false,
      },
      {
        field: "Length",
        headerName: "Length",
        width: 150,
        editable: false,
      }
      ,
      {
        field: "Width",
        headerName: "Width",
        width: 150,
        editable: false,
      },
      {
        field: "EdgeFinising",
        headerName: "EdgeFinising",
        type: "string",
        width: 150,
        editable: false,
      },
      {
        field: "ProductPrice",
        headerName: "ProductPrice",
        width: 150,
        editable: false,
      },
      {
        field: "RealSqPrice",
        headerName: "RealSqPrice",
        width: 150,
        editable: false,
      },
      {
        field: "EdgeFinishingCost",
        headerName: "EdgeFinishingCost",
        type: "string",
        width: 150,
        editable: false,
      },
      {
        field: "BindingTapeCost",
        headerName: "BindingTapeCost",
        width: 90,
        editable: false,
      },
      // {
      //   field: "RealSqPrice",
      //   headerName: "RealSqPrice",
      //   width: 90,
      //   editable: true,
      // },
      {
        field: "FinalPrice",
        headerName: "FinalPrice",
        type: "string",
        width: 90,
        editable: true,
      },
      {
        field: "Customer",
        headerName: "Customer",
        width: 90,
        editable: true,
      },
      {
        field: "Created By",
        headerName: "Created By",
        type: "string",
        width: 90,
        editable: true,
      },
      {
        field: "Date",
        headerName: "Date",
        type: "string",
        width: 90,
        editable: true,
      },
    ];
    return (
      <>

        <h4> History Page</h4>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={rows }
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
        
            checkboxSelection
            disableSelectionOnClick
          />
        </Box>
      </>
    );
  }

export default CalculationHistory;
