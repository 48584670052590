import React from "react";
import CalculationHistory from "../Components/CalculationHistory";
import PrimaryAppBar from "../Components/PrimarySearchAppBar";
import axios from "axios";
class HistoryPage extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
          rows: [],
        }
        this.updateRows = this.updateRows.bind(this);
        this.GetData();
      }
      updateRows(rowss) {
        this.setState({
          rows: rowss,
        });
      }
      GetData = () => {
    
        axios
          .post(global.config.Server.serverName + "/GetHistory")
          .then((res) => {
            //console.log('Getting data');
           // console.log(JSON.stringify(res.data));
            this.updateRows( res.data);
            console.log(res.data);
          })
          .catch((error) => {
            console.log(error);
          });
      };
    render(){

        return(
            <div>
                <PrimaryAppBar /> 
                <CalculationHistory  rows={this.state.rows}/> 
            </div>
        );
    }
}
 export default HistoryPage;