import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import InvoicePreview from '../Components/InvoiceComponents/InvoicePreview'

export default function InvoiceSharePreviewPage() {
    const search = useLocation().search;
    const invId = new URLSearchParams(search).get('uuid');
    const [data, setmydata] = useState({});

    useEffect(() => {
        (async () => {
            const uidData = { uid: invId };
            const res = await axios.post(global.config.Server.serverName + "/GetInvoice", JSON.stringify(uidData));
            const mydt = await res.data;
            await setmydata(mydt);
            await console.log(res.data);

        })()
    }, [invId])

   if(data===null)
    {
        return (<h2>Loading.....</h2>);
    }
    else if(data.Error !== undefined && data.Error !== null)
    {
        return (<h2>{data.Error}</h2>);
    }
    else{
        const { Lines, Company, Contact, Discount, Tax, Fees, Deals, Extras } = data;
        return (
    
            <>
    
                <InvoicePreview line={Lines}
                    company={Company}
                    contact={Contact}
                    discount={Discount}
                    tax={Tax}
                    fees={Fees}
                    deals={Deals}
                    extra={Extras}
                    callRefresh={''} />
            </>
        );

    }
}

