import { Button } from '@mui/material'
import './UpdateEntities.css'
import React, { useState } from 'react'
import axios from 'axios';


export default function UpdateEntities() {
    const [message, setMessage] = useState('');


    const UpdateEntities = (entityname) => {
       (async()=>{ setMessage('Loading '+entityname);
        const entti={Entity:entityname};
        const res=  await axios.post(global.config.Server.serverName + "/Update",JSON.stringify(entti));
        const data= await res.data;
        setMessage(data?.message);
        console.log(JSON.stringify(data));
    })();
    };
    return (
        <div className='Updateitems' >
            <h2>Update Entities</h2>
            <div className='Spacing'>

                <Button onClick={(event) => { event.preventDefault(); UpdateEntities('Product') }} type="button" variant='contained'>Product </Button>
                <Button onClick={(event) => { event.preventDefault(); UpdateEntities('Company') }} type="button" variant='contained'>Company </Button>
                <Button onClick={(event) => { event.preventDefault(); UpdateEntities('Contact') }} type="button" variant='contained'>contact </Button>
               
            </div>
            <label className='message' ><b>{message}</b></label>
        </div>
    )
}
