
module.exports = global.config = {
    Server:{
         //   serverName:"http://132.147.166.5:4000"
       // serverName:"https://app.rivieraindia.co.in:4000"
       // serverName:http://103.206.96.228:4000
       // serverName:'http://localhost:4000',
       serverName:'https://app.divyaliving.com:4000'
       //url:'https://app.divyaliving.com/'
    },

};

