import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  { field: "id", headerName: "ID", width: 40 },
  {
    field: "FirstName",
    headerName: "First Name",
    width: 150,
    editable: true,
  },
  {
    field: "LastName",
    headerName: "Last Name",
    width: 150,
    editable: true,
  },
  {
    field: "Company",
    headerName: "Company",
    type: "text",
    width: 150,
    editable: true,
  },
  {
    field: "AddressLine1",
    headerName: "AddressLine1",
    width: 150,
    editable: true,
  },
  {
    field: "AddressLine2",
    headerName: "AddressLine2",
    width: 150,
    editable: true,
  },
  {
    field: "Email",
    headerName: "Email",
    type: "text",
    width: 150,
    editable: true,
  },
  {
    field: "phone",
    headerName: "Phone",
    width: 90,
    editable: true,
  },
  {
    field: "Pincode",
    headerName: "Pincode",
    width: 90,
    editable: true,
  },
  {
    field: "Country",
    headerName: "Country",
    type: "text",
    width: 90,
    editable: true,
  },
];

export default function CustomerList({rows}) {
  return (
    <Box sx={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        disableSelectionOnClick
      />
    </Box>
  );
}
