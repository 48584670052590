import React, { useState } from "react";
import ImageHoverZoom from "./ImageHoverZoom";
import "./PreviewSection.css";

const PreviewSection = ({ productInfo }) => {
  const [imgUrl, setUrl] = useState("");
  const [active, setActive] = useState(1);
  return (
    <div>
      <div>
        <ImageHoverZoom
          imagePath={imgUrl === "" ? productInfo[0]?.PImage :imgUrl}
        />
      </div>
      <div className="container">
        {
        
        productInfo.map((value) => {
        //  console.log("id=" + value.id);
          return (
            <div key={value.id}>
              <div className="itemContainer" >
                <img
                  className={value.id !== active ? "item" : "selectedItem"}
                  src={value.Thumbnail}
                  width="40px"
                  alt=""
                  onClick={() => {
                    setUrl(value.PImage);
                    setActive(value.id);
                  }}
                />
                <label className="labelColor">{value.Color}</label>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default PreviewSection;
