import React from "react";
import PrimaryAppBar from "../Components/PrimarySearchAppBar";
import SRManagers from "../Components/SRManagers";
class SRManagersPage extends React.Component{
    render(){
        return(
            <div>
                
              <PrimaryAppBar/>
              <SRManagers />
            </div>
        );
    }
}

export default SRManagersPage;