import { Box } from "@mui/material";
import * as React from "react";
import "./InventoryList.css";
import {
  DataGrid,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,

} from "@mui/x-data-grid";
import { padding, width } from "@mui/system";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      
      <GridToolbarColumnsButton/>
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />
      <GridToolbarQuickFilter />
      <GridToolbarExport  />
    </GridToolbarContainer>
  );
}

const InventoryList = ({ rows }) => {
  const columns = [
    { field: "id", headerName: "ID", visible: "false" },
    {
      field: "Category",
      headerName: "Category",
      minWidth: 150,
      editable: true,
    },
    {
      field: "ProductThumbnails",
      headerName: "Thumbnails",

      renderCell: (params) => (
        <img
          src={params.value}
          loading="lazy"
          alt="thumbnail"
          className="thumb"
          width="100%"
        />
      ),
    },
    {
      field: "StyleName",
      headerName: "Style",
      editable: true,
      minWidth: 150,
    },
    {
      field: "Color",
      headerName: "Color",

      editable: true,
      minWidth: 150,
    },
    {
      field: "SKU",
      headerName: "SKU",

      editable: true,
      minWidth: 150,
    },
    // {
    //   field: "ProductLength",
    //   headerName: "Length",
    //   minWidth: 150,
    //   editable: true,
    // },
    // {
    //   field: "ProductWidth",
    //   headerName: "ProductWidth",
    //   minWidth: 150,
    //   editable: true,
    // },

    {
      field: "Size",
      headerName: "Size",

      editable: true,
      minWidth: 150,

    },
    {
      field: "Stock",
      headerName: "Stock",
      minWidth: 50,
      editable: true,
    },
    
    {
      field: "RollNo",
      headerName: "Roll No",
      minWidth: 150,
      editable: true,
    },
    
    {
      field: "DyelotNo",
      headerName: "DyeLot No",
      minWidth: 150,
      editable: true,
    },
  ];

  return (
    <>
      <h1> Inventory</h1>
      <Box sx={{ height: "100%", width: "100%" }}>
        <DataGrid
          autoHeight
          rows={rows}
          columns={columns}
          pageSize={100}
          rowsPerPageOptions={[20]}
          checkboxSelection={0}
          disableSelectionOnClick
          showQuickFilter
          components={{ Toolbar: CustomToolbar }}
          slots={{ toolbar: GridToolbarQuickFilter }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </Box>
    </>
  );
};
export default InventoryList;
