import React from "react";
import { TextField, Button } from "@mui/material";
import "./PasswordEntry.css";

class PasswordEntry extends React.Component {
  // const [password, setpassword] = useState(0);
  constructor(props) {
    super(props);
    this.state = {
      password: "",
    };

    this.StateUpdate = this.StateUpdate.bind(this);
    this.componentDidMount = this.componentDidMount(this);
  }
  componentDidMount() {}
  StateUpdate(st) {
    this.setState({ ...this.state, password: st });
  }

  render() {
    return (
      <div className="outer_cont">
        <div className="div_container">
          <div className="div_container2">
            <h1>DIVYA Living  Inventory</h1>
          <TextField
          inputProps={{
            style: {fontFamily:"Arial"} 
          }}
            margin="none"
            required
            name="password"
            label="Password"
            size="small"
            type="password"
            color="secondary"
            onChange={(e) => {
              this.StateUpdate(e.target.value);
            }}/>

          </div>
           
<div className="button_cont">
          <Button
            name="button_entry"
            variant="contained"
            onClick={() => this.props.callbackvalue(this.state.password)}
          >
            <b>Login</b>
          </Button>
        </div>
      </div></div>
    );
  }
}

export default PasswordEntry;
