import React from 'react'
import logo from '../../assets/Images/logosmall.png'
import BoxLineBlackBackground from './BoxLineBlackBackground'
import Button from '@mui/material/Button';

import './InvoiceComp.css'
export default function InvHeader({ elevatedAction }) {
  return (
    <div className='InvtopHeader'>

      <table>
        <thead></thead>
                        <tbody>
        <tr>
          <td style={{ display: 'flex', alignItems: 'initial', justifyItems: 'flex-start', width: '200%' }}>
            <img src={logo} width={'150%'} alt="logo" className='logoinv' />
          </td>
          <td style={{ verticalAlign: 'top', width: "400px" }}>
            <div id="printPanel"><b> <Button variant="text" onClick={() => elevatedAction(0)}>Print</Button>|<Button variant="text" onClick={() => elevatedAction(1)}>Download</Button>|<Button variant="text" onClick={() => elevatedAction(2)}>Refresh</Button></b></div>
          </td>
        </tr>
        </tbody>
      </table>

      <BoxLineBlackBackground text={"Invoice"}  />
    </div>
  )
}
