import * as React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import logo from '../assets/Images/logo.png'
import './../Global/variables';
import './login.css'
import { GetUserInfo } from "../ModelActions/UserHelper";


export default function Login() {
  const [errorMsg,setError]=React.useState("");
  const [username,setUsername]=React.useState("");
  const [password,setpassword]=React.useState("");
 //const styles={"-webkit-text-security": "square"};
  const handleSubmit = (event) => {
    
    event.preventDefault();
    
   var data= GetUserInfo(username,password);
   if(data["Error"]==="")
   {
       window.location.reload();
   }
   else{
    setError(data["Error"])
   }
  }
  const handleUser=(e)=>{
    setUsername(e.target.value);
  }
  const handlePass=(e)=>{
    setpassword(e.target.value);
  }


  return (

    <div  width="375px">
    <img src={logo} alt="logo"  width="70%"/>

    <Box component='form' onSubmit={handleSubmit}  noValidate width="300px">
      <div className="Box">
        <TextField
          margin="normal"
          size="small"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoComplete="Username"
          autoFocus
          onChange={handleUser}
          color="primary"
        />
        <TextField
          margin="normal"
          required
          fullWidth

          name="password"
          label="Password"
          type=""
          id="password"
     //     style={styles}
          autoComplete="current-password"
          onChange={handlePass}
          helperText={errorMsg}
          color="primary"
        />

        <Button type="submit"  fullWidth variant="contained" >
          Sign In
        </Button>
        {/* <Grid container>
          <Grid item xs>
            <Link href="#" variant="body2">
              Forgot password?
            </Link>
          </Grid>
          <Grid item>
            <Link href="#" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid> */}
      </div>
    </Box>
    </div>
  );
}
